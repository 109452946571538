export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  messages: {
    en: {
      'Choose a date to see available tours':
        'Choose a date to see available tours',
      'Your name': 'Your name',
      'Email address': 'Email address',
      'Phone number': 'Phone number',
      'How many people will be joining?': 'How many people will be joining?',
      'Where do you live?': 'Where do you live?',
      'Loading tour dates': 'Loading tour dates',
      'Tour is fully booked': 'Tour is fully booked',
      'Unfortunately, the selected tour is fully booked. Please select select a different tour or time.':
        'Unfortunately, the selected tour is fully booked. Please select select a different tour or time.',
      'You are also welcome to meet us at the designated meeting point for the tour, however a spot on the tour is not guaranteed.':
        'You are also welcome to meet us at the designated meeting point for the tour, however a spot on the tour is not guaranteed.',
      'Select a tour': 'Select a tour',
      Description: 'Description',
      'Trusted partner tours': 'Trusted partner tours',
      'Select a language': 'Select a language',
      optional: 'optional',
      'This will be used to send you a confirmation, reminder, and follow-up.':
        'This will be used to send you a confirmation, reminder, and follow-up.',
      'This will be used to contact you in case of tour issues.':
        'This will be used to contact you in case of tour issues.',
      'The maximum group size for this tour is {max} adults':
        'The maximum group size for this tour is {max} adults',
      'The group size limit is enforced and making multiple bookings to bypass this rule is not allowed.':
        'The group size limit is enforced and making multiple bookings to bypass this rule is not allowed.',
      'If you are a group of {max} or more':
        'If you are a group of {max} or more',
      'please send us an email': 'please send us an email',
      'to coordinate a private tour': 'to coordinate a private tour',
      'Please select a country': 'Please select a country',
      'Make booking': 'Make booking',
      'Unfortunately, the selected tour is fully booked.':
        'Unfortunately, the selected tour is fully booked.',
      'Please choose a different tour or time.':
        'Please choose a different tour or time.',
      'Please choose a tour first': 'Please choose a tour first',
      'Please fill out the entire booking form':
        'Please fill out the entire booking form',
      'Please add your name': 'Please add your name',
      'Please choose a tour': 'Please choose a tour',
      'Please choose a language': 'Please choose a language',
      'Please Choose a date first': 'Please Choose a date first',
      'Please add your email': 'Please add your email',
      'Please enter a valid email address':
        'Please enter a valid email address',
      'Please select': 'Please select',
      'Previous month': 'Previous month',
      'Next month': 'Next month',
      Mo: 'Mo',
      Tu: 'Tu',
      We: 'We',
      Th: 'Th',
      Fr: 'Fr',
      Sa: 'Sa',
      Su: 'Su',
      Monday: 'Monday',
      Tuesday: 'Tuesday',
      Wednesday: 'Wednesday',
      Thursday: 'Thursday',
      Friday: 'Friday',
      Saturday: 'Saturday',
      Sunday: 'Sunday',
      Jan: 'Jan',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Apr',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Aug',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dec',
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
      'per person': 'per person',
      'per group': 'per group',
      'Please enter a valid phone number': 'Please enter a valid phone number',
      Duration: 'Duration',
      'Meeting point': 'Meeting point',
      'Want to add additional comments?': 'Want to add additional comments?',
      Comments: 'Comments',
      Adults: 'Adults',
      'Ages 17+': 'Ages 17+',
      Children: 'Children',
      'Ages 0-16': 'Ages 0-16',
      'Please choose a date first': 'Please choose a date first',
      'Which FTC member referred you to join us?':
        'Which FTC member referred you to join us?',
      'Please select a referral member of Free Tour Community or "None"':
        'Please select a referral member of Free Tour Community or "None"',
      'Select a Free Tour Community member':
        'Select a Free Tour Community member',
      None: 'None',
    },
    es: {
      Adults: 'Adultos',
      'Ages 17+': 'Edades 17+',
      Children: 'Niños',
      'Ages 0-16': 'Edades 0-16',
      'Please choose a date first': 'Por favor, elige una fecha primero',
      'Which FTC member referred you to join us?':
        '¿Cuál miembro de FTC te recomendó unirte?',
      'Please select a referral member of Free Tour Community or "None"':
        'Por favor, selecciona un miembro de la Free Tour Community o "Ninguno"',
      'Select a Free Tour Community member':
        'Selecciona un miembro de la Free Tour Community',
      Comments: 'Comentarios',
      'Want to add additional comments?':
        '¿Quieres agregar comentarios adicionales?',
      'Choose a date to see available tours':
        'Selecciona una fecha para ver los tours disponibles',
      'Your name': 'Su nombre',
      'Email address': 'Correo electrónico',
      'Phone number': 'Número de teléfono',
      'How many people will be joining?': '¿Cuántas personas se unirán?',
      'Where do you live?': '¿Desde dónde nos estás visitando?',
      'Loading tour dates': 'Cargando fechas',
      'Tour is fully booked': 'El tour está lleno',
      'Unfortunately, the selected tour is fully booked. Please choose a different tour or time.':
        'Desafortunadamente, el tour seleccionado está lleno. Por favor, elige un tour o horario diferente.',
      'You are also welcome to meet us at the designated meeting point for the tour; however, a spot on the tour is not guaranteed.':
        'Usted también puede encontrarnos en el punto de encuentro designado para el tour; sin embargo, no le garantizamos un lugar en el tour.',
      'Choose a tour': 'Elige un tour',
      Description: 'Descripción',
      'Trusted partner tours': 'Tours con socios de confianza',
      'Choose a language': 'Elige un idioma',
      optional: 'opcional',
      'This will be used to send you a confirmation, reminder, and follow-up.':
        'Esto se utilizará para enviarle una confirmación, recordatorio y seguimiento.',
      'This will be used to contact you in case of tour issues.':
        'Esto se usará para contactarte en caso de problemas relacionados con el tour.',
      'The maximum group size for this tour is {max} adults':
        'El tamaño máximo del grupo para este tour es de {max} adultos',
      'The group size limit is enforced and making multiple bookings to bypass this rule is not allowed.':
        'Se aplica el límite de tamaño del grupo y no se permite realizar múltiples reservas para evitar esta regla.',
      'If you are a group of {max} or more': 'Si es un grupo de {max} o más',
      'please send us an email': 'por favor, envíanos un correo electrónico',
      'to coordinate a private tour': 'para coordinar un tour privado',
      'Please select a country': 'Elige un país',
      'Make booking': 'Realizar reserva',
      'Unfortunately, the selected tour is fully booked.':
        'Desafortunadamente, el tour seleccionado está lleno.',
      'Please choose a different tour or time.':
        'Por favor, elige un tour o horario diferente.',
      'Please choose a tour first': 'Por favor, elige un tour primero',
      'Please fill out the entire booking form':
        'Por favor, complete todo el formulario de reserva',
      'Please add your name': 'Ingrese su nombre',
      'Please choose a tour': 'Elige un tour',
      'Please choose a language': 'Elige un idioma',
      'Please Choose a date first': 'Por favor, elige una fecha primero',
      'Please add your email': 'Por favor, agregue su correo electrónico',
      'Please enter a valid email address':
        'Por favor, ingrese una dirección de correo electrónico válida',
      'Please select': 'Elige',
      'Previous month': 'Mes anterior',
      'Next month': 'Próximo mes',
      Mo: 'Lu',
      Tu: 'Ma',
      We: 'Mi',
      Th: 'Ju',
      Fr: 'Vi',
      Sa: 'Sá',
      Su: 'Do',
      Monday: 'Lunes',
      Tuesday: 'Martes',
      Wednesday: 'Miércoles',
      Thursday: 'Jueves',
      Friday: 'Viernes',
      Saturday: 'Sábado',
      Sunday: 'Domingo',
      Jan: 'Ene',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Abr',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Ago',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dic',
      January: 'Enero',
      February: 'Febrero',
      March: 'Marzo',
      April: 'Abril',
      May: 'Mayo',
      June: 'Junio',
      July: 'Julio',
      August: 'Agosto',
      September: 'Septiembre',
      October: 'Octubre',
      November: 'Noviembre',
      December: 'Diciembre',
      'per person': 'por persona',
      'per group': 'por grupo',
      'Please enter a valid phone number':
        'Por favor, ingrese un número de teléfono válido',
      Duration: 'Duración',
      'Meeting point': 'Punto de encuentro',
      None: 'Ninguno',
    },
    it: {
      Adults: 'Adulti',
      'Ages 17+': 'Età 17+',
      Children: 'Bambini',
      'Ages 0-16': 'Età 0-16',
      'Please choose a date first': 'Per favore, scegli prima una data',
      'Which FTC member referred you to join us?':
        'Quale membro di FTC ti ha consigliato di unirti a noi?',
      "Please select a referral member of Free Tour Community or 'None'":
        "Per favore, seleziona un membro della Free Tour Community o 'Nessuno'",
      'Select a Free Tour Community member':
        'Seleziona un membro della Free Tour Community',
      Comments: 'Commenti',
      'Want to add additional comments?':
        'Vuoi aggiungere commenti aggiuntivi?',
      'Choose a date to see available tours':
        'Scegli una data per vedere i tour disponibili',
      'Your name': 'Il tuo nome',
      'Email address': 'Indirizzo email',
      'Phone number': 'Numero di telefono',
      'How many people will be joining?': 'Quante persone si uniranno?',
      'Where do you live?': 'Da dove vieni?',
      'Loading tour dates': 'Caricamento delle date del tour',
      'Tour is fully booked': 'Il tour è al completo',
      'Unfortunately, the selected tour is fully booked. Please choose a different tour or time.':
        'Purtroppo, il tour selezionato è al completo. Per favore, scegli un altro tour o orario.',
      'You are also welcome to meet us at the designated meeting point for the tour; however, a spot on the tour is not guaranteed.':
        "Puoi anche raggiungerci al punto d'incontro designato per il tour; tuttavia, non garantiamo un posto sul tour.",
      'Choose a tour': 'Scegli un tour',
      Description: 'Descrizione',
      'Trusted partner tours': 'Tour con partner fidati',
      'Choose a language': 'Scegli una lingua',
      optional: 'opzionale',
      'This will be used to send you a confirmation, reminder, and follow-up.':
        'Questo verrà utilizzato per inviarti una conferma, un promemoria e un follow-up.',
      'This will be used to contact you in case of tour issues.':
        'Questo verrà utilizzato per contattarti in caso di problemi con il tour.',
      'The maximum group size for this tour is {max} adults':
        'Il numero massimo di partecipanti per questo tour è {max} adulti',
      'The group size limit is enforced and making multiple bookings to bypass this rule is not allowed.':
        'Il limite massimo di partecipanti è applicato e non è consentito fare più prenotazioni per aggirare questa regola.',
      'If you are a group of {max} or more':
        'Se siete un gruppo di {max} o più persone',
      'please send us an email': "per favore, inviaci un'email",
      'to coordinate a private tour': 'per coordinare un tour privato',
      'Please select a country': 'Seleziona un paese',
      'Make booking': 'Effettua prenotazione',
      'Unfortunately, the selected tour is fully booked.':
        'Purtroppo, il tour selezionato è al completo.',
      'Please choose a different tour or time.':
        'Per favore, scegli un altro tour o orario.',
      'Please choose a tour first': 'Per favore, scegli prima un tour',
      'Please fill out the entire booking form':
        "Per favore, completa l'intero modulo di prenotazione",
      'Please add your name': 'Inserisci il tuo nome',
      'Please choose a tour': 'Scegli un tour',
      'Please choose a language': 'Scegli una lingua',
      'Please Choose a date first': 'Per favore, scegli prima una data',
      'Please add your email': 'Per favore, aggiungi il tuo indirizzo email',
      'Please enter a valid email address':
        'Per favore, inserisci un indirizzo email valido',
      'Please select': 'Seleziona',
      'Previous month': 'Mese precedente',
      'Next month': 'Mese successivo',
      Mo: 'Lu',
      Tu: 'Ma',
      We: 'Me',
      Th: 'Gi',
      Fr: 'Ve',
      Sa: 'Sa',
      Su: 'Do',
      Monday: 'Lunedì',
      Tuesday: 'Martedì',
      Wednesday: 'Mercoledì',
      Thursday: 'Giovedì',
      Friday: 'Venerdì',
      Saturday: 'Sabato',
      Sunday: 'Domenica',
      Jan: 'Gen',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Apr',
      Jun: 'Giu',
      Jul: 'Lug',
      Aug: 'Ago',
      Sep: 'Set',
      Oct: 'Ott',
      Nov: 'Nov',
      Dec: 'Dic',
      January: 'Gennaio',
      February: 'Febbraio',
      March: 'Marzo',
      April: 'Aprile',
      May: 'Maggio',
      June: 'Giugno',
      July: 'Luglio',
      August: 'Agosto',
      September: 'Settembre',
      October: 'Ottobre',
      November: 'Novembre',
      December: 'Dicembre',
      'per person': 'per persona',
      'per group': 'per gruppo',
      'Please enter a valid phone number':
        'Per favore, inserisci un numero di telefono valido',
      Duration: 'Durata',
      'Meeting point': "Punto d'incontro",
      None: 'Nessuno',
    },
  },
}))
